<template>
    <div class="page_content">
        <!-- <a-spin size="large" v-show="loading" /> -->
        <div class="page_edit_content">
            <Form ref="form" :form_data="form_data" @get_date='get_date' @handleSubmit="handleSubmit" @handleChange="handleChange">
                <div slot="uid">
                    <a @click="add_user()">
                        <a-icon v-if="!select_user.username" type="plus-circle" />
                        <span style="padding-left: 5px">{{
                            select_user.username
                                ? select_user.username
                                : "添加业务员"
                        }}</span>
                    </a>
                    <a-input type="hidden" name="uid" v-decorator="['uid', uid]" />
                </div>
                <div slot="pid">
                    <a @click="add_customer()">
                        <a-icon v-if="!select_customer.name" type="plus-circle" />
                        <span style="padding-left: 5px">{{
                            select_customer.name
                                ? select_customer.name
                                : "添加上级配送商"
                        }}</span>
                    </a>
                    <a-input type="hidden" name="pid" v-decorator="['pid', pid]" />
                </div> 
                <div slot="dealer_id">
                    <a @click="add_dealer()">
                        <a-icon v-if="!select_dealer.name" type="plus-circle" />
                        <span style="padding-left: 5px">{{
                            select_dealer.name
                                ? select_dealer.name
                                : "添加所属经销商"
                        }}</span>
                    </a>
                    <a-input type="hidden" name="dealer_id" v-decorator="['dealer_id', dealer_id]" />
                </div>
                <div slot='distributor' v-if="group_id==505 && this.$router.currentRoute.query.id && get_seller.length !== 0" class="distributor_box">
                <!-- <div slot='distributor' class="distributor_box"> -->
					<div class="title">经销商补充信息：</div>
					<Form
					    :form_data="seller_form_data"
					    ref="seller_form"
					>
						<div slot="sap_seller_id">{{get_seller.sap_seller_id || '无'}}</div>
						<div slot="seller_id">{{get_seller.seller_id || '无'}}</div>
					</Form>
				</div>
                <div slot="contact_data">
                    <a @click="add_contact()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加联系人</span>
                    </a>
                    <a-input type="hidden" name="contact_data" v-decorator="['contact_data', contact_data]" />
                    <div>
                        <ul>
                            <li v-for="(item, index) in contact_person" :key="index">
                                <div>
                                    <span>联系人：{{ item.contact_name }}</span>
                                    <span>手机号：{{ item.contact_phone }}</span>
                                    <span>职务：{{
                                            item.contcact_type_name
                                        }}</span>
                                </div>
                                <a @click="del_contact(item)">删除</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div slot="warehouse_data">
                    <a @click="add_warehouse()">
                        <a-icon type="plus-circle" />
                        <span style="padding-left: 5px">添加收货地址</span>
                    </a>
                    <a-input type="hidden" name="warehouse_data" v-decorator="['warehouse_data', warehouse_data]" />
                    <div>
                        <ul>
                            <li v-for="(item, index) in warehouse_address" :key="index">
                                <div>
                                    <div>
                                        <span>联系人：{{
                                                item.contact_name
                                            }}</span>
                                        <span>手机号：{{
                                                item.contact_phone
                                            }}</span>
                                    </div>
                                    <div>收货地址：{{ item.address }}</div>
                                </div>

                                <a @click="del_warehouse(item)">删除</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div slot="wx">
                    <div v-if="info.applets.length">
                        <div v-for="item in info.applets">
                            {{ item.nickname }}
                            <a-divider type="vertical" />
                            <a-popconfirm title="您确定要解绑吗?" v-auth="'delete'" @confirm="untie_wx_mini(item)">
                                <a>解绑</a>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div v-else>无</div>
                </div>
            </Form>
        </div>

        <SelectUser @submit="submit" :visible.sync="visible"></SelectUser>
        <SelectCustomer @submit="select_customer_submit" :visible.sync="pid_visible"></SelectCustomer>
        <SelectCustomer @submit="select_dealer_submit" :visible.sync="dealer_visible"></SelectCustomer>
        <EditPop @handleSubmit="contact_submit" :form_data="form_data_contact" :visible.sync="contact_visible">
        </EditPop>
        <EditPop @handleSubmit="warehouse_submit" :form_data="form_data_warehouse" :visible.sync="warehouse_visible">
            <div slot="address">
                <a class="add_address" @click="add_address">
                    <a-icon v-if="!map.address" type="plus-circle" />
                    <span style="padding-left: 5px" v-if="!map.address">选择地址</span>
                    <span style="padding-left: 5px; display: block"
                        v-if="map.province || map.city || map.district">省市区：{{ map.province }}{{ map.city
                        }}{{ map.district }}</span>
                    <span style="padding-left: 5px; display: block" v-if="map.address">详细地址：{{ map.address }}</span>
                </a>
            </div>
        </EditPop>
        <Map @handleSubmit="map_submit" :visible.sync="map_visible">
            <!-- 地图 -->
            <div style="padding-bottom: 20px" slot="map" class="map_select">
                <simple-map @get_title="get_title"></simple-map>
            </div>
        </Map>
    </div>
</template>

<script>
    import Form from "@/components/Form";
    import SelectUser from "@/components/SelectUser";
    import SelectCustomer from "@/components/SelectCustomer";
    import EditPop from "@/components/EditPop";
    import warehouse from "@/components/EditPop";
    import Map from "@/components/EditPop";
    import SimpleMap from "@/components/BaiduMap";
    import {
        getModifyCustomerInfo,
        cancelBinding,
        getCustomerDetails,
        modify
    } from "@/api/customer";

    export default {
        components: {
            Form,
            SelectUser,
            EditPop,
            SelectCustomer,
            warehouse,
            Map,
            SimpleMap
        },
        data() {
            return {
                group_id: -1,
                get_seller: {},
                expand: false,
                page: 1,
                pagination: {
                    ...this.$config.pagination
                },
                list: [],
                select_user: [],
                select_customer: [],
                select_dealer:[],
                pid: {},
                dealer_id:{},
                loading: true,
                imageUrl: "",
                visible: false,
                contact_visible: false,
                pid_visible: false,
                dealer_visible:false,
                warehouse_visible: false,
                map_visible: false,
                map: {},
                uid: {},
                contact_data: {},
                contact_person: [],
                warehouse_data: {},
                warehouse_address: [],
                datas: {},
                info: {
                    applets: []
                },
                form_data_contact: {
                    title: "添加联系人",
                    list: [{
                            type: "text",
                            name: "contact_name",
                            title: "联系人",
                            placeholder: "姓名",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写联系人姓名"
                                }]
                            }
                        },
                        {
                            type: "text",
                            name: "contact_phone",
                            title: "联系方式",
                            placeholder: "手机号",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写联系人手机号"
                                }]
                            }
                        },
                        {
                            type: "select",
                            name: "contact_type",
                            title: "职务",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择联系人职务"
                                }]
                            },
                            list: []
                        }
                    ]
                },
                form_data: {
                    title: "基本信息",
                    show_submit_btn: true,
                    show_close_btn: true,
                    close_reset_btn: true,
                    list: [{
                            type: "text",
                            name: "id",
                            hidden: true,
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "name",
                            title: "客户名称",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请输入客户名称"
                                }],
                                initialValue: ""
                            }
                        },
                        {
                            type: "radio",
                            name: "status",
                            title: "客户状态",
                            relation_name: 'close_desc',
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择客户状态"
                                }],
                                initialValue: "1"
                            },
                            list: [{
                                    key: "1",
                                    value: "运营中"
                                },
                                {
                                    key: "-1",
                                    value: "封存"
                                }
                            ]
                        },

                        {
                            type: "text",
                            name: 'close_desc',
                            title: "封存原因",
                            close: true,
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写封存原因"
                                }],
                                initialValue: ""
                            }
                        },
                        // {
                        //   type: "slot",
                        //   name: "signs_img",
                        //   title: "门头照片",
                        //   options: {
                        //     initialValue: ""
                        //   }
                        // },
                        {
                            type: "select",
                            name: "level_id",
                            title: "客户等级",
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择客户等级"
                                }],
                                initialValue: ""
                            },
                            list: []
                        },
                        {
                            type: "select",
                            name: "region_type",
                            title: "城乡类型",
                            mode: "default",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择客户城乡类型"
                                }],
                                initialValue: ""
                            },
                            list: []
                        },
                        {
                            type: "cascader",
                            name: "group_id",
                            title: "客户分组",
                            list: [],
                            placeholder: "请选择客户分组",
                            mode: "default",
                            options: {
								rules: [{
								    required: true,
								    message: "请选择客户分组"
								}],
                                initialValue: []
                            }
                        },
                        // {
                        //     type: "text",
                        //     name: "sap_seller_id",
                        //     placeholder: "如经销商客户可填写SAP编号",
                        //     title: "SAP编号",
                        //     options: {

                        //         initialValue: ""
                        //     }
                        // },
                        {
                            type: "slot",
                            name: "distributor",
                            title: "",
                        },
                        {
                            type: "slot",
                            name: "uid",
                            title: "业务员",
							required: true,
                            options: {
                                initialValue: []
                            }
                        },
                        //   {
                        //     type: "select",
                        //     name: "line_id",
                        //     title: "路线名称",
                        //     mode: "default",
                        //     options: {
                        //       initialValue: ""
                        //     },
                        //     list: [
                        //       {
                        //         key: "系统管理员",
                        //         value: "系统管理员"
                        //       },
                        //       {
                        //         key: "高层管理员",
                        //         value: "高层管理员"
                        //       }
                        //     ]
                        //   },
                        {
                            type: "slot",
                            name: "pid",
                            title: "上级配送商",
							required: true,
                            mode: "default",
                            options: {
                                initialValue: ""
                            },
                            list: []
                        },
                        {
                            type: "slot",
                            name: "dealer_id",
                            title: "所属经销商",
							required: false,
                            mode: "default",
                            options: {
                                initialValue: ""
                            },
                            list: []
                        },
						{
						    type: "select",
						    name: "visit_frequency",
						    title: "拜访频率",
						    mode: "default",
						    options: {
						        rules: [{
						            required: true,
						            message: "请选择拜访频率"
						        }],
						        initialValue: ""
						    },
						    list: [
								{key: 1, value: "每周1访"},
								{key: 2, value: "每周2访"},
								{key: 3, value: "每周3访"},
								{key: 4, value: "每周4访"},
								{key: 5, value: "每周5访"},
								{key: 6, value: "每周6访"},
								{key: 7, value: "每周7访"},
							]
						},
                        {
                            type: "tree-select",
                            name: "department_id",
                            title: "服务区域",
                            options: {
								rules: [{
									required: true,
									message: "请选择服务区域"
								}],
                                initialValue: ""
                            },
                            treeData: [],
                            multiple: false
                        },
                        {
                            type: "text",
                            name: "address",
                            title: "门店地址",
                            mode: "default",
                            placeholder: "精确到门牌号",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写门店地址"
                                }],
                                initialValue: ""
                            }
                        },

                        {
                            type: "slot",
                            name: "contact_data",
                            title: "联系人",
                            mode: "default",
                            required:true,
                          options: {
                             
                          }
                        },
                        {
                            type: "slot",
                            name: "warehouse_data",
                            title: "收货地址",
                            mode: "default",
                              required:true,
                            options: {
                                initialValue: "",
                               
                            }
                        },
                        {
                            type: "text",
                            name: "business_license_name",
                            title: "营业执照名称",
                            options: {
                                initialValue: ""
                            }
                        },
                        // {
                        //   type: "upload",
                        //   name: "business_license_img",
                        //   title: "营业执照",
                        //   options: {}
                        // },
                        {
                            type: "text",
                            name: "tax_number",
                            title: "工商注册号",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "legal_entity",
                            title: "法人姓名",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "business_area",
                            title: "营业面积（m^2）",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "cashier_num",
                            title: "收银台个数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "shelves_num",
                            title: "货架组数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "count_put_num",
                            title: "总排面数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "put_num",
                            title: "润田排面数",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "text",
                            name: "business_hours",
                            title: "营业时间",
                            options: {
                                initialValue: ""
                            }
                        },
                        {
                            type: "checkbox",
                            name: "facility",
                            title: "门店设施",
                            options: {
                                initialValue: []
                            },
                            list: []
                        },
                        {
                            type: "slot",
                            name: "wx",
                            hidden: !this.$route.query.id,
                            title: "动销小程序",
                            options: {
                                initialValue: ""
                            }
                        },
                    ]
                },
                form_data_seo: {
                    title: "请选择人员",
                    show_submit_btn: true,
                    submit_name: "查询",
                    layout: "inline",
                    list: [{
                            type: "tree-select",
                            name: "department_id",
                            title: "部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "tree-select",
                            name: "role_id",
                            title: "岗位职务",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        // {
                        //     type: "select",
                        //     name: "role_id",
                        //     title: "岗位职务",
                        //     mode: "default",
                        //     options: {},
                        //     list: []
                        // },
                        {
                            type: "text",
                            name: "keyword",
                            title: "姓名",
                            options: {}
                        }
                    ]
                },
                form_data_pid: {
                    title: "请选择人员",
                    show_submit_btn: true,
                    submit_name: "查询",
                    layout: "inline",
                    list: [{
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "客户名称/地址/ID",
                        options: {}
                    }]
                },
                form_data_warehouse: {
                    title: "添加仓库",
                    list: [{
                            type: "text",
                            name: "contact_name",
                            title: "联系人",
                            placeholder: "姓名",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写联系人姓名"
                                }]
                            }
                        },
                        {
                            type: "text",
                            name: "contact_phone",
                            title: "联系方式",
                            placeholder: "手机号",
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写联系人手机号"
                                }]
                            }
                        },
                        {
                            type: "slot",
                            name: "address",
                            title: "详细地址",
                              required:true,
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请填写联系人手机号"
                                }]
                            }
                        }
                    ]
                },
				seller_form_data:{
					list: [
					    {
					        type: "radio",
					        name: "status",
					        title: "状态",
					        options: {
					            initialValue: "1"
					        },
							list: [{
							        key: "1",
							        value: "是"
							    },
							    {
							        key: "0",
							        value: "否"
							    }
							],
					    },
						{
							type:'area',
							name:'region',
							title:'所属地区',
							value:'',
						},
						{
							type:'slot',
							name:'sap_seller_id',
							title:'Sap编号',
							value:'',
						},
						{
							type:'slot',
							name:'seller_id',
							title:'DRP编号',
							value:'',
						},
						{
							type:'text',
							name:'phone',
							title:'短信接收号',
							value:'',
						},
						{
							type:'text',
							name:'credit_count',
							title:'信用额度',
							value:'',
						},
						{
							type:'text',
							name:'bond_money',
							title:'保证金',
							value:'',
						},
						{
							type:'text',
							name:'seller_count',
							title:'账上余额',
							value:'',
						},
					]
				}
            };
        },
        async created() {
               let id = this.$router.currentRoute.query.id;
            this.get_data();
            this.$method.get_level().then(res => {
                this.form_data.list.forEach(item => {
                    if (item.name == "level_id") {
                        item.list = res;
                    }
                });
            });

            this.$method.get_RegionType().then(res => {
                this.form_data.list.forEach(item => {
                    if (item.name == "region_type") {
                        item.list = res;
                    }
                });
            });
            this.$method.get_group().then(res => {
                this.form_data.list.forEach(item => {
                    if (item.name == "group_id") {
                        if(!id){
                          res.splice(0,1)  
                        }
                        item.list = res;
                    }
                });
            });
            this.$method.get_role_tree().then(res => {
                this.form_data_seo.list.forEach(item => {
                    if (item.name == "role_id") {
                        item.treeData = res;
                    }
                });
            });
            // this.$method.get_role().then(res => {
            //     this.form_data_seo.list.forEach(item => {
            //         if (item.name == "role_id") {
            //             item.list = res;
            //         }
            //     });
            // });
         
            if (id) {
                setTimeout(() => {
                    this.get_list(id);
                }, 200);
            }
        },
        methods: {
            get_date(e){
                let arr = e.join(',');
                if(arr.indexOf('505') <0){
                     this.group_id= -1
                }else {
                    this.group_id ='505'
                }
                if (this.group_id == 505) {
                    this.form_data.list.forEach(item => {
                        if (item.name == "pid") {
                            item.required = false;
                        }
                    });
                } else {
                    this.form_data.list.forEach(item => {
                        if (item.name == "pid") {
                            item.required = true;
                        }
                    });
                }  
            },
            async get_list(e) {
                try {
                    await getCustomerDetails({
                        id: e || ""
                    }).then(res => {
                        
                        this.loading = false;
                        let list = res.data.customer_data;
                        this.info = list;
                        this.get_seller = res.data.customer_data.get_seller
                        this.group_id = res.data.customer_data.group_id;
                        this.form_data.list.forEach((item,index) => {
                            if(item.name=='group_id'){
                                if(this.group_id != 505) {
                                   item.list.splice(0,1) 
                                }
                            }
                            for (let vo in list) {
                                if (item.name == vo) {
                                    if (item.type == "date") {
                                        item.options.initialValue = this.$moment(
                                            list[vo],
                                            "YYYY/MM/DD"
                                        );
                                    } else if (item.name == "level_id") {
                                        this.datas.level_list.forEach(io => {
                                            if (list[vo] == io.id) {
                                                item.options.initialValue = io.id;
                                            }
                                        });
                                    } else if (item.name == "visit_frequency") {
										item.options.initialValue = list[vo];
									} else if (item.type == "cascader") {
                                        list.group_ids = list.group_ids.slice(0, list.group_ids
                                            .length - 1)

                                        let ids = list.group_ids.split(',')
                                        ids = ids.map(io => {
                                            io = Number(io)
                                            return io
                                        })
                                        item.options.initialValue = ids;
                                    } else if (item.type == "checkbox") {
                                        let data = list.facility;
                                        data = data.map(io => {
                                            if (io.status) {
                                                item.options.initialValue.push(io.id);
                                            }
                                        });
                                    } else {
                                        if (item.name == 'status' && list[vo] == -1) {

                                            let data = this.$method.get_form_relation_name(
                                                item,
                                                this.form_data.list,
                                                item.name
                                            );
                                            data.relation_name.close = false;
                                        }
                                        if (list[vo] != null) {
                                            item.options.initialValue = list[vo].toString();
                                        }
                                    }
                                } else if (item.type == "slot") {
                                   
                                    if (item.name == "uid") {
                                        if (
                                           list.get_customer_belong_user&&list.get_customer_belong_user.id

                                        ) {

                                            this.select_user = list.get_customer_belong_user;
                                            this.$refs.form.setFieldsValue({
                                                uid: this.select_user.id
                                            });
                                        } else {
                                            this.select_user = [];
                                            this.$refs.form.setFieldsValue({
                                                uid: ""
                                            });
                                        }


                                    } else if (item.name == "contact_data") {
                                        if (
                                            list.get_customer_contact &&
                                            list.get_customer_contact.length
                                        ) {
                                            let data = list.get_customer_contact;
                                            let contact_person = [];
                                            data.forEach(item => {
                                                contact_person.push({
                                                    contact_type: item.contact_type.id,
                                                       uid: item.id,
                                                    contact_type_name: item
                                                        .contact_type.name,
                                                    contact_name: item.contact_name,
                                                    contact_phone: item
                                                        .contact_phone
                                                });
                                            });

                                            this.contact_person = contact_person;
                                        } else {
                                            this.contact_person = [];
                                        }
                                        
                                        this.$refs.form.setFieldsValue({
                                            contact_data: JSON.stringify(this.contact_person)
                                        });
                                    } else if (item.name == "pid") {
                                        if (list.get_pid_customer) {
                                            this.select_customer = list.get_pid_customer;
                                            this.$refs.form.setFieldsValue({
                                                pid: JSON.stringify(this.select_customer.id)
                                            });
                                        }
                                        if (list.customer_type == 505) {
                                            this.form_data.list.forEach(item => {
                                                if (item.name == "pid") {
                                                    item.required = false;
                                                }
                                            });
                                        } else {
                                            this.form_data.list.forEach(item => {
                                                if (item.name == "pid") {
                                                    item.required = true;
                                                }
                                            });
                                        }

                                    } else if (item.name == 'dealer_id') {
                                        if (list.get_dealer) {
                                            this.select_dealer = list.get_dealer;
                                            this.$refs.form.setFieldsValue({
                                                dealer_id: JSON.stringify(this.select_dealer.id)
                                            });
                                        }
                                    } else if (item.name == "warehouse_data") {
                                        if (
                                            list.get_customer_ware_house &&
                                            list.get_customer_ware_house.length
                                        ) {
                                            let data = list.get_customer_ware_house;
                                            let warehouse_address = [];
                                            data.forEach(item => {
                                                warehouse_address.push({
                                                    contact_name: item.contact_name,
                                                    district: item.district,
                                                    latitude: item.latitude,
                                                    longitude: item.longitude,
                                                    city: item.city,
                                                    contact_phone: item.contact_phone,
                                                    province: item.province,
                                                    address:item.address,
                                                    id:item.id,
                                                    customer_id:item.customer_id,
                                                });
                                            });

                                            this.warehouse_address = warehouse_address;
                                        } else {
                                            this.warehouse_address = [];
                                        }

                                        this.$refs.form.setFieldsValue({
                                            warehouse_data: JSON.stringify(this
                                                .warehouse_address)
                                        });
                                    }
                                }
                            }
                        });/* this.form_data.list */
						this.$nextTick(()=>{
							this.seller_form_data.list.forEach((item,index) => {
								for (let vo in this.get_seller) {
								    if (item.name == vo) { 
										if(item.name == 'region'){
											this.$refs.seller_form.setFieldsValue({
												[item.name]: this.get_seller[vo].split(',')
											});										
										}else{
											this.$refs.seller_form.setFieldsValue({
												[item.name]: this.get_seller[vo].toString()
											});
										}
									}
								}
							})
						})
						
                    });
                } catch {}
				this.$method.get_department(false,this.select_user.id?this.select_user.id:0).then(res => {
				    this.form_data_seo.list.forEach(item => {
				        if (item.name == "department_id") {
				            item.treeData = res;
				        }
				    });
				    this.form_data.list.forEach(item => {
				        if (item.name == "department_id") {
				            item.treeData = res;
				        }
				    });
				});
            },

            async get_data() {
                try {
                    await getModifyCustomerInfo().then(res => {
                        let data = res.data;
                        this.datas = data;
                        data.contact_type_list = data.contact_type_list.map(item => {
                            item.key = item.id;
                            item.value = item.name;
                            return item;
                        });
                        data.facility_list = data.facility_list.map(item => {
                            item.key = item.id;
                            item.value = item.name;
                            return item;
                        });
                        this.form_data_contact.list.forEach(item => {
                            if (item.name == "contact_type") {
                                item.list = data.contact_type_list;
                            }
                            if (item.name == "facility") {
                                item.list = data.facility_list;
                            }
                        });

                        this.form_data.list.forEach(item => {
                            if (item.name == "facility") {
                                item.list = data.facility_list;
                            }
                        });
                    });
                } catch {}
            },
            add_user() {
                this.visible = true;
            },
            add_customer() {
                this.pid_visible = true;
            },
            add_dealer() {
                this.dealer_visible = true;
            },
            add_warehouse() {
                this.warehouse_visible = true;
            },
            add_contact() {
                this.contact_visible = true;
            },
            add_address() {
                this.map_visible = true;
            },
            submit({
                data
            }) {
                this.visible = false;
                this.select_user = data;
                this.$refs.form.setFieldsValue({
                    uid: this.select_user.id
                });
				this.$method.get_department(false,this.select_user.id?this.select_user.id:0).then(res => {
				    this.form_data_seo.list.forEach(item => {
				        if (item.name == "department_id") {
				            item.treeData = res;
				        }
				    });
				    this.form_data.list.forEach(item => {
				        if (item.name == "department_id") {
				            item.treeData = res;
				        }
				    });
				});
            },
            select_customer_submit({
                data
            }) {
                this.pid_visible = false;
                this.select_customer = data;
                this.$refs.form.setFieldsValue({
                    pid: this.select_customer.id
                });
            },
            select_dealer_submit({
                data
            }) {
                this.dealer_visible = false;
                this.select_dealer = data;
                this.$refs.form.setFieldsValue({
                    dealer_id: this.select_dealer.id
                });
            },
            contact_submit(e) {
                let value = e.values;
                this.datas.contact_type_list.forEach(item => {
                    if (value.contact_type == item.id) {
                        value.contcact_type_name = item.name;
                    }
                    return value;
                });
                this.contact_person.push(value);
                this.$refs.form.setFieldsValue({
                    contact_data: JSON.stringify(this.contact_person)
                });
                this.contact_visible = false;
            },
            warehouse_submit(e) {
                let value = e.values;
                let map = this.map;
                map.contact_name = value.contact_name;
                map.contact_phone = value.contact_phone;
                this.warehouse_address.push(map);
                this.$refs.form.setFieldsValue({
                    warehouse_data: JSON.stringify(this.warehouse_address)
                });
                this.warehouse_visible = false;
            },
            map_submit(e) {
                let value = e.values;

                this.map_visible = false;
            },
            get_title(e) {
                let map = {
                    address: e.address,
                    longitude: e.point.lng,
                    latitude: e.point.lat,
                    province: e.province,
                    city: e.city,
                    district: "",
                    longitude: e.point.lng,
                    latitude: e.point.lat
                };
                this.map = map;
            },
            del_contact(params) {
                this.contact_person.forEach((item, index) => {
                    if (params == item) {
                        this.contact_person = this.contact_person
                            .slice(0, index)
                            .concat(
                                this.contact_person.slice(index + 1, this.contact_person.length)
                            );
                    }
                });
                this.$refs.form.setFieldsValue({
                    contact_data: JSON.stringify(this.contact_person)
                });
            },
            del_warehouse(params) {
                this.warehouse_address.forEach((item, index) => {
                    if (params == item) {
                        this.warehouse_address = this.warehouse_address
                            .slice(0, index)
                            .concat(
                                this.warehouse_address.slice(
                                    index + 1,
                                    this.warehouse_address.length
                                )
                            );
                    }
                });
                this.$refs.form.setFieldsValue({
                    warehouse_data: JSON.stringify(this.warehouse_address)
                });
            },
            handleChange(data) {
                let name = "status";
                let {
                    e
                } = data;
                if (e.target.name == name) {
                    data = this.$method.get_form_relation_name(
                        e.target,
                        this.form_data.list,
                        name
                    );
                    data.relation_name.close = e.target.value !== "-1";
                }
            },
            handleSubmit(e) {
				if(this.get_seller.length === 0){
					this.deal_submit(e,{});
				}else{
					if(this.$refs.seller_form){
						this.$refs.seller_form.submit().then(res=>{
							 this.deal_submit(e,res)// res是经销商补充信息的表单数据结果
						})
					}else{
						this.deal_submit(e,{})
					}
				}
				
            },
			deal_submit(e,seller){
				let value = e.values;
				let arr = {};
				this.datas.facility_list.forEach(item => {
					arr[item.id] = false;
					value.facility.forEach(vo => {
						if (vo == item.id) {
							arr[vo] = true;
						}
					});
				});
				let contact_person = []
							   
				this.contact_person.forEach(item => {
					contact_person.push({
						id:item.uid,
						contact_name: item.contact_name,
						contact_phone: item.contact_phone,
						contact_type: item.contact_type,
					})
				})
				let obj = {
					...value,
					group_id: value.group_id.pop(),
					facility: JSON.stringify(arr),
					contact_data: JSON.stringify(contact_person)
				};
				
				try {
					modify({
						data: {
							...obj,
							seller // 经销商补充信息的表单数据结果
						},
					}).then(res => {
						this.$router.go(-1);
					})
				
				} catch {}
			},
            untie_wx_mini(data) {
                cancelBinding({
                    data: {
                        id: data.id
                    },
                    info: true,
                }).then(res => {
                    this.get_list(this.$route.query.id)
                })
            }
        }
    };
</script>
<style lang="less" scoped>
    .map_select {
        position: relative;

        .serach_input {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    ul {
        padding: 0;
    }

    li {
        display: block;
        list-style: none;
        display: flex;

        span {
            padding-right: 10px;

            display: inline-block;
        }
    }

    .avatar-uploader>.ant-upload {
        width: 128px;
        height: 128px;
    }

    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }

    .distributor {
        width: 60%;
        margin-left: 1.5%;
        padding: 5px 10px;
        padding-bottom: 10px;
    }

    .distributor_box {
		background-color: rgba(250, 250, 250, 1);
		margin-left: 14%;
		padding-bottom: 20px;
		.title{
			margin-left: 24px;
		}
		/deep/.ant-col-5{
			width: 7%;
		}
    }
</style>
